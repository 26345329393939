import { Link } from 'react-router-dom'
import { orderArrayByProperty, getBackgroundImage, shuffleArray } from '../../../utils/pageUtils'

const Grid = ({ gridData, gridPageCategory, isHome }) => {

    let gridPages = gridData.filter(
        page => 
            (page.category) && 
            (isHome ? page.show_in_home : page.category?.id === gridPageCategory.id)
    )

    gridPages = gridPages.filter( page => !page.is_category_grid_page && !page.is_home )
    gridPages = gridPages.map(page => {
        if(page.order_value === null) {
            page.order_value = 9999
        }
        return page
    })
    
    if(isHome) {
        shuffleArray(gridPages)
    } else {
        gridPages.sort(orderArrayByProperty('order_value'))
    }

    return (
        <>
            <div className="container-fluid">
                <div className="row project-list">       
                    {gridPages.map((categoryPage, index) => (
                        <div key={index} className="col-sm-6 col-md-4 project-thumb">
                            <Link className="hover-on-tap" to={`/${categoryPage.locale}/${categoryPage.category.slug}/${categoryPage.slug}`}>
                                <div className="img-container ratio-3x2">
                                    <img
                                        src={categoryPage.meta_image && getBackgroundImage(categoryPage.meta_image)}
                                        className="img-responsive"
                                        alt={categoryPage.meta_image?.alternativeText}
                                        / >
                                </div>
                                <div className="overlay">
                                    <div className="title">
                                        <span>{categoryPage.name}</span>
                                    </div>
                                </div>                        
                            </Link>
                        </div>   
                    ))}          
                </div>
            </div>
        </>
    )
}

Grid.defaultProps = {
    gridPageCategory: 0
}

export default Grid
