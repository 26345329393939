import { Route, useParams, withRouter} from 'react-router-dom'
import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { get } from './utils/http'
import { filterPublishedContent } from './utils/pageUtils'
import Routes from './routes/Routes'
import './assets/vendor/bootstrap-3.3.6-dist/css/bootstrap.min.css'
import './assets/vendor/font-awesome-4.6.3/css/font-awesome.min.css'
import './assets/sass/vertical-rhythm.scss'
import './assets/sass/App.scss'
import ReactGA from 'react-ga'

ReactGA.initialize(process.env.REACT_APP_CDP_GA)


export default function App({ location }) {
  const locales = [{locale: 'en'}, {locale: 'lv'}, {locale: 'ru'} ]
  const [pageContent, setPageContent] = useState([])
  const [pageNavbar, setPageNavbar] = useState([])
  const [pageLocales, setPageLocales] = useState(locales)
  const [currentLocale, setCurrentLocale] = useState('')
  let { locale } = useParams()

  useEffect(() => {
    const getPageContent = async () => {
      const pageNavbar = await fetchPageNavbar()
      setPageNavbar(pageNavbar)
      const pageContent = await fetchPageContent()
      setPageContent(pageContent)
    }

    const getPageLocales = async () => {
      // const pageLocales = await fetchPageLocales()
      const updatedLocales = addCurrentLocale(locale, pageLocales)
      setPageLocales(updatedLocales)
    }
    
    setCurrentLocale(locale)
   
    setPageLocale()
    getPageContent()
    // getPageLocales()
  }, [locale])

  const setPageLocale = () => {
    const currentLocale = locale
    setCurrentLocale(currentLocale)
  }

  const fetchPageContent = async () => {
    const resp = await get(`${process.env.REACT_APP_CDP_API_URL}/pages?_locale=${locale}&_limit=-1`).then(resp => {
      return resp
    }).catch(error => {
      console.log(error)
    });
    return filterPublishedContent(resp.data)
  }

  const fetchPageNavbar = async () => {
    const resp = await get(`${process.env.REACT_APP_CDP_API_URL}/menu?_locale=${locale}`).then(resp => {
      return resp
    }).catch(error => {
      console.log(error)
    });
    
    return resp.data
  }

  const fetchPageLocales = async () => {
    const resp = await get(`${process.env.REACT_APP_CDP_API_URL}/locales`).then(resp => {
      return resp
    }).catch(error => {
      console.log(error)
    });    

    return resp.data
  }

  const addCurrentLocale = (locale, pageLocales) => {
    pageLocales.map(pageLocale => {
      if(pageLocale.locale === locale) {
        pageLocale.is_current_locale = true
        return pageLocale
      }
    })
    return pageLocales
  }

  return (
    <Route path="/:locale">
      {pageNavbar.menu && pageContent ? 
        <Routes pageContent={pageContent} 
              pageNavbar={pageNavbar}
              pageLocales={pageLocales} 
              currentLocale={currentLocale} />
      : ""}
    </Route>
        
  );
} 

// export default App;
