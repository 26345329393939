import { useParams } from 'react-router-dom'
import { useState, useEffect } from 'react'
import Slider from '../blocks/slider/Slider'
import Image from '../blocks/image/Image'
import Text from '../blocks/text/Text'
import Video from '../blocks/video/Video'
import Map from '../blocks/map/Map'
import Meta from '../common/meta/Meta'
import Loader from '../common/loader/Loader'
import Grid from '../common/grid/Grid'

const Page = ({ content, isHome, currentLocale }) => {
    let { slug, parentslug } = useParams()
    const [currentPage, setCurrentPage] = useState(content)
    const [isLoading, setIsLoading] = useState(false)

    const notFound = <div></div>

    useEffect(() => {
      // setIsLoading(true)
      // console.log("Slug, parentslug? ", slug, parentslug)
      const getPageContent = async () => {
        const page = 
          !isHome ? 
            content.filter(
              page => 
                parentslug && page.category ? 
                    !page.is_category_grid_page && 
                    page.category.slug === parentslug && page.slug === slug 
                : page.slug === slug
            )  
          : content.filter(page => page.is_home)
        setCurrentPage(page)
      }
      
      getPageContent()
    }, [content, slug, parentslug])

    
  
    return (
      <>
        {currentPage.length < 1 ? notFound : ''}
        {currentPage.map((page, index) => (
          <>
              <div key={index}>
                <Meta page={page} locale={currentLocale} />
                {(page.is_category_grid_page || page.is_home) ? 
                <section key={index} id={page.slug} className="project-grid">
                  <Grid 
                    isHome = {page.is_home}
                    gridData={content} 
                    isCategoryGridPage={page.is_category_grid_page} 
                    gridPageCategory={page.category} />
                </section>
                :
                <section key={index} id={page.slug} 
                className={`page-grid page-${page.slug} ${page.rows.length > 0 && 
    page.rows[0].__component === 'block-type.image' ? '' : ''}`}>
                  
                  <div className="container-fluid">
                    <>
                      {page.rows.map((row, index) => (
                        <div key={index} className="block-row">
                            {row.__component === 'block-type.image' && 
                              <Image pageData={page} isSingleImage imageData={row} /> 
                            }
                            {row.__component === 'block-type.text' && 
                              <Text pageData={page} textData={row} />
                            }
                            {row.__component === 'block-type.text-video' &&
                              <>
                                {row.is_reverse_order ? (
                                  <>
                                    <Video pageData={page} videoURL={row.video.video_url} /> 
                                    <Text pageData={page} textData={row} />
                                  </>) : (<>
                                    <Text pageData={page} textData={row} />
                                    <Video pageData={page} videoURL={row.video.video_url} /> 
                                  </> )}
                              </>
                            }
                            {row.__component === 'block-type.image-text' &&
                              <>
                                {row.is_reverse_order ? (
                                  <>
                                    <Text pageData={page} textData={row} />
                                    <Image pageData={page} imageData={row.image} /> 
                                  </>) : (<>
                                    <Image pageData={page} imageData={row.image} /> 
                                    <Text pageData={page} textData={row} />
                                  </>)}
                              </>
                            }
                            {row.__component === 'block-type.image-image' &&
                              <>
                                {row.images.map((imageData, index) => (
                                  <Image key={index} pageData={page} imageData={imageData} />
                                ))}
                              </>
                            }
                            {row.__component === 'block-type.text-text' &&
                              <>
                                {row.texts.map((textData, index) => (
                                  <Text key={index} pageData={page} textData={textData} />
                                ))}
                              </>
                            }
                            {row.__component === 'visual-components.slider' &&
                              <>
                                <Slider slides={row.slides} />
                              </>
                            }
                            {row.__component === 'visual-components.map' &&
                              <>
                                <Map pageData={row} />
                              </>
                            }
                        </div>
                      ))}
                    </>
                  </div>
                </section>
                }
                
            </div> 
          </>
        ))}
      </>
    )
}

export default Page
